<template>
    <b-overlay :show="showLoader">
        <b-card class="my-1 d-flex align-center" :title="$t('menu.subcategory.warehouses')">
            <div class="d-flex justify-content-end" v-if="$hasPermission($permissions.ConfigurationWrite)">
                <b-button variant="primary" @click="$refs.addModal.open()">
                    <feather-icon icon="PlusSquareIcon"/>
                    {{ $t('config.warehouses.add_warehouse') }}
                </b-button>
            </div>

            <Table class="mt-1" :columnDefs="columnDefs" :rowData="warehouses" @deleteRow="remove" @editRow="editRow" @edit="edit"/>
        </b-card>
        <AddWarehouse ref="addModal" v-on:itemAdded="loadData"/>
    </b-overlay>
</template>

<script>
    import Table from '@/views/components/Table/Table'
    //import ActiveEditor from '../components/Table/ActiveEditor.vue'
    import AddWarehouse from '@/views/Warehouse/AddWarehouse'
    import {BButton, BCard, BOverlay} from 'bootstrap-vue'
    import * as Sentry from '@sentry/vue'
    import itemChange from '@core/mixins/installation-wizard/itemChange'
    import {Tiers} from '@/libs/enums/Tiers'

    export default {
        mixins: [itemChange],
        components: {Table, AddWarehouse, BCard, BOverlay, BButton},
        props: {
            buttonType: {
                type: String,
                default: 'ViewStock'
            }
        },
        computed: {
            Tiers() {
                return Tiers
            },
            columnDefs() {
                const hasPermission =  this.$hasPermission(this.$permissions.ConfigurationWrite)

                return [
                    {headerName: this.$t('table_fields.name'), editable: hasPermission, field: 'name', filter: true},
                    {
                        headerName: this.$t('table_fields.default_warehouse'),
                        editable: hasPermission,
                        field: 'default_warehouse',
                        cellRenderer: 'DisplayActive',
                        cellEditorFramework: 'activeEditor',
                        filter: true,
                        floatingFilterComponentParams: {
                            type: 'select',
                            values: [
                                {name: this.$t('general.yes'), value: true}, {
                                    name: this.$t('general.no'),
                                    value: false
                                }
                            ],
                            selectValue: 'value'
                        }
                    },
                    {
                        headerName: this.$t('table_fields.actions'),
                        editable: false,
                        filter: false,
                        sortable: false,
                        colId: 'actions',
                        cellRenderer: 'btnCellRenderer',
                        cellRendererParams: {button: this.buttonType},
                        minWidth: 150
                    }
                ]
            }
        },
        data() {
            return {
                warehouses: [],
                showLoader: false
            }
        },
        methods: {
            async loadData() {
                try {
                    this.showLoader = true

                    const response = await this.$http.get('/api/client/v1/warehouses/')
                    this.warehouses = response.data ?? []
                    await this.itemChange()
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                } finally {
                    this.showLoader = false
                }
            },
            async edit(params) {
                try {
                    this.showLoader = true

                    const editObject = {object_data:{[params.column.colId]: params.newValue}}
                    await this.$http.patch(`/api/client/v1/warehouses/${params.data.id}`, editObject)
                    this.$printSuccess(this.$t('print.success.edit'))
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_edit'))
                } finally {
                    this.showLoader = false
                    await this.loadData()
                }
            },
            editRow(data) {
                this.$refs.addModal.open(data)
            },
            async remove(id) {
                try {
                    const confirmDelete = await this.$confirmDialog(this.$t('print.confirm.delete_row'))
                    if (!confirmDelete) return
                    await this.$http.delete(`/api/client/v1/warehouses/${id}`)
                    this.$printSuccess(this.$t('print.success.delete'))
                    await this.loadData()

                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_delete'))
                }
            }
        },
        mounted() {
            this.loadData()
        }
    }
</script>

<style scoped>

</style>
